import { FilterArea } from '@core/VisualComponents/Page/PageHeader/PageHeaderRowEnganced/FilterArea/FilterArea';
import { FilterAreaButton } from '@core/VisualComponents/Page/PageHeader/PageHeaderRowEnganced/FilterArea/FilterAreaButton/FilterAreaButton';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { DeviceTypeContext } from '@core/Contexts/DeviceTypeContext';
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useFiltersView } from '@core/Redux/hooks';
import { setCurrentFilter } from '@core/Redux/Slices/filtersViewSlice';
import { crmFilterToWhereClause } from '@core/JsStore/mappers/crm-filter-to-where-clause';
import { IWhereQuery } from 'jsstore';
import { selectSortField, selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import { createFilters } from './OrderFiltersMisc';
import _ from 'lodash';
import { useLocalStorage } from '@core/Hooks/use-local-storage';
import styles from "./OrderFIlter.module.scss";
import { IQuerySpecification } from "@core/EventSourcing/Implementation/LiveQuery";
import { IRemovableValueOption, RemovableSelect } from '@core/VisualComponents/RemovableSelect/RemovableSelect';
import { StoreContext } from '@core/Stores/EventSourcingStoreProvider';
import { ReactComponent as AddViewIcon } from "@assets/Icons/add-view-icon.svg";
import { createQueryVars } from '@core/Stores/QueryVars';
import { CrmSortDirection } from '@core/Models/autogenerated/tenantConfig.models';
import { ICrmFilter } from '@core/Models/tenantConfig.models';


export interface IFilter {
    tableId: string;
    id: string;
    type: string;
    caption: string;
    fields?:string[];
    canRemove: boolean;
    defaultSortDirection: CrmSortDirection;
    simpleQueries: any;
    setSimpleQueries: (query: any) => void;
    onClick: () => void;
    onRemove: () => void;

    getQuery(limit: number, sortField: string | null): IQuerySpecification;
}

export interface IOrderFiltersProps {
    tableId: string;

    onFilterChange: (filter: IFilter) => void,
    onResetAllSimpleFilters: () => void;
    showResetButton?: boolean;
}

export function OrderFilters(props: IOrderFiltersProps) {
    const { t } = useTranslation();
    const deviceType = useContext(DeviceTypeContext);
    const userInfo = useAppSelector(selectUserInfo)!;
    const dispatch = useAppDispatch();
    const [defaultFilterId, setDefaultFilterId] = useLocalStorage<string | null>('orderSelectedFilterId', { initialValue: null });
    const view = useFiltersView();
    const stores = useContext(StoreContext);
    const store = stores[props.tableId];
    const defaultSortField = useAppSelector(selectSortField(props.tableId));

    const selectFilter = useCallback((filterId: string | null) => {
        setCurrentFilter({ id: filterId, offset: 0 });
    }, []);

    let config = useAppSelector(selectTenantConfig);
    const tableConfig = config?.tables?.find(x => x.tableId === props.tableId);

    const filters = useMemo(() => {
        let filters = createFilters(config
            , props.tableId
            , selectFilter
            , userInfo
            , dispatch
            , t);

        return filters;
    }
        , [config, props.tableId, selectFilter, userInfo]
    );

    const currentFilter = useMemo(() => {
        let result = filters.find(x => x.id === view.currentFilterId);

        if (!result) {
            result = filters.find(x => x.id === defaultFilterId);
        }
        if (!result) {
            result = filters.find(x => x.type == AllFilter.TYPE);
        }
        if (!result) {
            result = _.first(filters);
        }

        return result ?? null;
    }, [filters, view.currentFilterId, defaultFilterId]);

    useEffect(() => {
        if (currentFilter?.id != view.currentFilterId) {
            setCurrentFilter({ id: currentFilter?.id ?? null, offset: 0 });
        }
    }, [currentFilter?.id]);

    useEffect(() => {
        if (currentFilter) {
            props.onFilterChange(currentFilter);

            if (currentFilter.type != SearchFilter.TYPE)
                setDefaultFilterId(currentFilter.id);
        }
    }, [props.onFilterChange, currentFilter]);

    const filterOptions: IRemovableValueOption[] = useMemo(() => filters
        ?.map(x => ({
            value: x.id,
            label: x.caption,
            canRemove: x.canRemove,
        } as IRemovableValueOption)) ?? []
        , [filters]);

    const defaultFilterOpt: IRemovableValueOption = useMemo(() =>
        ({ value: '', label: t("no_selection"), canRemove: false })
        , []);

    const selectedFilterOpt: IRemovableValueOption = useMemo(() =>
        filterOptions.find(x =>
            x.value === currentFilter?.id) ?? defaultFilterOpt
    , [filterOptions, currentFilter, defaultFilterOpt]);

    const filterClicked = useCallback((id?: string) => {
        const selected = filters.find(x => x.id == id);
        if (selected) {
            selected.onClick();
        }
    }, [filters]);

    const AddFilterButton = () => {
        return (
            <span onClick={() => addFilter()} className={styles.AddButtonContainer}>
                <AddViewIcon className={styles.icon}/>
                <span className={styles.text}>{t("add-view")}</span>
            </span>

        );
    };

    function addFilter() {

        const chatButtons = document.getElementsByClassName('chat-button');
        const chatInputs = document.getElementsByClassName('chat-input');

        if (chatInputs && chatInputs.length > 0) {
            const chatButton = chatButtons[0] as HTMLElement;
            addFilterText();
        }
        else {

            if (chatButtons && chatButtons.length > 0) {
                const chatButton = chatButtons[0] as HTMLElement;
                chatButton.click();
                delayedExec(addFilterText);
            }
        }

        function addFilterText() {
            const chatInput = chatInputs[0] as HTMLInputElement;
            const tableName = tableConfig?.tableName ?? '...'
            const inputTExt = t("add-filter-text").replaceAll('{tableName}', tableName);
            chatInput.value = inputTExt;
            chatInput.focus();
        }

        function delayedExec(f: { (): void; }) {
            let timerId = setInterval(() => {
                if (document.getElementsByClassName('chat-input').length > 0
                ) {
                    clearInterval(timerId)
                    f()
                }
            }, 100);
        }


    }

    const selectClassNames = {
        menu: () => styles.filterSelect,
        container: () => styles.filterSelectContainer,
    }

    const onRemoveFilter = (id: string) => {
        const filter = filters.find(x => x.id === id);
        if (filter) {
            store.disposeLiveQueryMonitorByQuery(filter.getQuery(0, defaultSortField), createQueryVars(userInfo));
            filter.onRemove();
        }
    }

    return (
        <FilterArea>
            {userInfo?.role == 'SuperUser' && !deviceType.isMobile &&
                <AddFilterButton />
            }
            <span className={styles.title}>{t("views")}:</span>
            {deviceType.isMobile
                ? <RemovableSelect
                    options={filterOptions}
                    value={selectedFilterOpt}
                    classNames={selectClassNames}
                    onChange={(e) => filterClicked(e?.value)}
                    onRemove={onRemoveFilter}
                    confirmRemove
                />
                : <>{filters && filters.map((filter) =>
                    <FilterAreaButton key={filter.id}
                        selected={currentFilter?.id === filter.id || filter.type == SearchFilter.TYPE}
                        caption={filter.caption}
                        canRemove={filter.canRemove}
                        onClick={() => filterClicked(filter.id)}
                        onRemove={() => onRemoveFilter(filter.id)}
                        confirmRemove
                    />)
                }</>
            }
        </FilterArea>);
}


export class BaseFilter {
    tableId: string;
    simpleQueries: any = {};

    constructor(tableId: string) {
        this.tableId = tableId;
    }

    public setSimpleQueries(query: any) {
        this.simpleQueries = query;
    }
}

export class SearchFilter extends BaseFilter implements IFilter {
    static TYPE: string = "search";
    id: string = "search";
    type: string = SearchFilter.TYPE;
    caption: string;
    text: string;
    defaultSortDirection: CrmSortDirection = CrmSortDirection.Desc;
    canRemove: boolean = true;
    onClick: () => void;
    onRemove: () => void;


    constructor(tableId: string, t: any, text: string, defaultSortDirection: CrmSortDirection, onSelect: (filter: IFilter) => void, onRemove: (filter: IFilter) => void) {
        super(tableId);
        this.caption = t("search") + ": " + text;
        this.text = text;
        this.defaultSortDirection = defaultSortDirection;
        this.onClick = () => onSelect(this);
        this.onRemove = () => onRemove(this);
    }

    public getQuery(limit: number, sortField: string | null): IQuerySpecification {
        return {
            fullTextSearch: this.text,
            where: 'getAll',
            orderBy: sortField,
        }
    }
}

export class AllFilter extends BaseFilter implements IFilter {
    static TYPE: string = "all";
    id: string;
    type: string = AllFilter.TYPE;
    caption: string;
    defaultSortDirection: CrmSortDirection = CrmSortDirection.Desc;
    canRemove: boolean = false;
    onClick: () => void;
    onRemove: () => void = () => { };

    constructor(tableId: string, id = "", caption = "", defaultSortDirection: CrmSortDirection, onSelect: (filter: IFilter) => void) {
        super(tableId);
        this.id = id;
        this.caption = caption;
        this.defaultSortDirection = defaultSortDirection;
        this.onClick = () => onSelect(this);
    }

    public getQuery(limit: number, defaultSortField: string | null): IQuerySpecification {
        if (_.isEmpty(this.simpleQueries)) {
            return {
                where: 'getAll',
                orderBy: defaultSortField,
            };
        }
        return {
            where: this.simpleQueries,
            orderBy: defaultSortField,
            transient: true,
        }
    }
}


export class QueryFilterV2 extends BaseFilter implements IFilter {
    static TYPE: string = "query_v2";
    id: string;
    type: string = QueryFilterV2.TYPE;
    caption: string;
    fields?: string[];
    defaultSortDirection: CrmSortDirection = CrmSortDirection.Desc;
    canRemove: boolean = false;
    onClick: () => void;
    onRemove: () => void = () => { };

    crmFilter: ICrmFilter;

    constructor(tableId: string, crmFilter: ICrmFilter, defaultSortDirection: CrmSortDirection, onSelect: (filter: QueryFilter) => void, onRemove: (filter: IFilter) => void) {
        super(tableId);
        this.id = crmFilter.id;
        this.caption = crmFilter.caption;
        this.fields = crmFilter.fields;
        this.defaultSortDirection = crmFilter.sortDirection ?? defaultSortDirection;
        this.onClick = () => onSelect(this);
        this.onRemove = () => onRemove(this);
        this.crmFilter = crmFilter;
        this.canRemove = !!crmFilter.aiGenerated;
    }

    public getQuery(limit: number, defaultSortField: string | null): IQuerySpecification {
        let orderBy: string|null;
        if (this.crmFilter.sortField) {
            orderBy = this.crmFilter.sortField;
        } else {
            orderBy = defaultSortField;
        }

        if (_.isEmpty(this.simpleQueries)) {
            return {
                where: this.crmFilter.where,
                orderBy: orderBy
            };
        }

        return {
            where: {
                '$and': [
                    this.crmFilter.where,
                    this.simpleQueries,
                ]
            },
            orderBy: orderBy,
            transient: true,
        };
    }
}

export class QueryFilter extends BaseFilter implements IFilter {
    static TYPE: string = "query";
    id: string;
    type: string = QueryFilter.TYPE;
    caption: string;
    fields?: string[];
    defaultSortDirection: CrmSortDirection = CrmSortDirection.Desc;
    canRemove: boolean = false;
    onClick: () => void;
    onRemove: () => void = () => { };

    crmFilter: ICrmFilter;

    constructor(tableId: string, crmFilter: ICrmFilter, defaultSortDirection: CrmSortDirection, onSelect: (filter: QueryFilter) => void, onRemove: (filter: IFilter) => void) {
        super(tableId);
        this.id = crmFilter.id;
        this.caption = crmFilter.caption;
        this.fields = crmFilter.fields;
        this.defaultSortDirection = crmFilter.sortDirection ?? defaultSortDirection;
        this.onClick = () => onSelect(this);
        this.onRemove = () => onRemove(this);
        this.crmFilter = crmFilter;
        this.canRemove = !!crmFilter.aiGenerated;
    }

    public getQuery(limit: number, sortField: string | null): IQuerySpecification {
        const filterConditionsDeclared = this.crmFilter && this.crmFilter.conditions != null && this.crmFilter.conditions.length > 0;
        const where: IWhereQuery | undefined = filterConditionsDeclared
            ? crmFilterToWhereClause(this.crmFilter.conditions ?? null)
            : undefined;

        let orderBy: string|null;
        if (this.crmFilter.sortField) {
            orderBy = this.crmFilter.sortField;
        } else {
            orderBy = sortField;
        }

        if (_.isEmpty(this.simpleQueries)) {
            return {
                where: where,
                orderBy: orderBy
            };
        }

        return {
            where: {
                '$and': [
                    where,
                    this.simpleQueries,
                ]
            },
            orderBy: orderBy,
            transient: true,
        };
    }
}