import { EventRemoteStream, IEventStreamEvent } from '@core/EventSourcing/EventRemoteStream';
import { fullDataCacheEnabled } from '@core/EventSourcing/EventSourcingStore';
import { mapAccordingToSchema } from '@core/JsStore/mappers/map-according-to-db-schema';
import { IEntityData } from '@core/Models/i-entity';
import { selectDbSchema, selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import { useAppDispatch, useAppSelector } from '@core/Redux/hooks';
import { applyFromYmdToUnixTimestampTransormation } from '@core/ServiceComponents/OperationEventPoller/apply-field-data-transormations';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { QueueHandler, QueueStore } from './QueueStore';
import { ICrmOperationEvent } from '@core/Models/autogenerated/operation.models';
import { updateEntityCountAsync } from '@core/Redux/Slices/ordersSlice/thunks/updateOrdersCountAsync';
import { AllFilter, QueryFilter } from 'src/App/Pages/OrdersPage/OrderFilters/OrderFilters';
import Logger from 'js-logger';
import { serializeError } from 'serialize-error';
import { IQuerySpecification } from "@core/EventSourcing/Implementation/LiveQuery";
import { fakeUserNameKey } from '@core/Constants/app-storage-keys';
import { updateDownloadingProgress, updateUploadingQueueLength } from '@core/Redux/Slices/ordersSlice/storesSlice';
import { generateKeywordsByEntity } from '@core/EventSourcing/Implementation/KeywordsGenerator';
import { IDbEntity } from '@core/JsStore/stores/shared/models/i-db-entity';
import i18n from 'src/Locale/i18n';
import { createQueryVars } from './QueryVars';
import { useAutocompleteStores } from './AutocompleteStoreProvider';
import { getAutocompleteStore } from '@core/Redux/Slices/appSlice';
import { eventSourcingRpc } from '@core/config';
import { IEventSourcingStoreReactClient } from '@core/EventSourcing/EventSourcingClientBase';
import { EventSourcingStoreReactRpcClient } from '@core/EventSourcing/EventSourcingStoreReactRpcClient';
import { EventSourcingReactLocalClient } from '@core/EventSourcing/EventSourcingReactLocalClient';
import { matchPath, useLocation } from 'react-router-dom';
import { entityEditPathMaskFull } from '@core/Constants/route-paths';
import { CrmFieldDataTransormation, ITableConfig } from '@core/Models/autogenerated/tenantConfig.models';

// Create a context with default values
export const StoreContext = React.createContext<Record<string, IEventSourcingStoreReactClient>>(null!);
export const useStore = (tableId: string) => useContext(StoreContext)[tableId];

export function EventSourcingStoreProvider(props: any) {
    const dispatch = useAppDispatch();

    const dbSchema = useAppSelector(selectDbSchema);
    const config = useAppSelector(selectTenantConfig);
    const tables = config?.tables;
    const esnode = config?.esnode; 
    const userInfo = useAppSelector(selectUserInfo);

    const location = useLocation();

    const isEditEntity = matchPath(
      { path: entityEditPathMaskFull, end: false }, // end: false allows for wildcard matching
      location.pathname
    );

    const transformOrderData = useCallback((entityData: IEntityData, tableConfig: ITableConfig) => {
        let changedEntityData: IEntityData | undefined = undefined;

        const keys = Object.keys(entityData);
        for (let key of keys) {
            const value = entityData[key];
            if (value === null || value === undefined)
                continue;

            const fieldConfig = tableConfig?.fields?.find(x => x.id === key
                && x.dataTransformations
                && x.dataTransformations.length > 0);

            if (!fieldConfig)
                continue;

            const dataTransformations = fieldConfig.dataTransformations ?? [];
            for (let dataTransformation of dataTransformations) {
                switch (dataTransformation) {
                    case CrmFieldDataTransormation.FromYmdToUnixTimestamp:
                        const modifiedValue = applyFromYmdToUnixTimestampTransormation(value);
                        if (modifiedValue != null && modifiedValue !== value) {
                            if (!changedEntityData)
                                changedEntityData = {...entityData};

                            changedEntityData[key] = modifiedValue;
                        }
                        break;
                    default:
                        throw new Error('ArgumentOutOfRange');
                }
            }
        }

        return changedEntityData || entityData;
    }, [tables]);

    let stores: Record<string, IEventSourcingStoreReactClient> = useMemo(() => {
        if (tables == null || userInfo == null)
            return {};

        let clientInstance = JSON.parse(localStorage.getItem(fakeUserNameKey) ?? '');

        let stores: Record<string, IEventSourcingStoreReactClient> = {};

        for (let table of tables) {
            const dataAdapter = (data : IEntityData) => {
                let transformedData = transformOrderData(data, table);
                let modifiedOrderData = mapAccordingToSchema(transformedData, dbSchema!.tables.find(x => x.name === table.tableId)!);
                return modifiedOrderData;
            }
        
            const postprocess = (entity: IDbEntity) => {
                entity.entityData._keywords = generateKeywordsByEntity(entity, table, i18n.language);
                return entity;
            }

            //let autocompleteStore = autocompleteStores[table.tableId];
            let autocompleteStore = getAutocompleteStore(table.tableId);
            
            let eventPendingQueue = (handler: QueueHandler<IEventStreamEvent>) => new QueueStore(table.tableId, userInfo.tenant, handler);
            let eventStream = new EventRemoteStream(table.tableId, eventPendingQueue);
        
            const rpc = eventSourcingRpc(esnode);
            let store: IEventSourcingStoreReactClient;

            Logger.info(`Es-node settings: rpc=${rpc.enabled}, url=${rpc.url}`);

            if (rpc.enabled) {
                store = new EventSourcingStoreReactRpcClient(table.tableId, userInfo.tenant, clientInstance, rpc.url);
                //store = new OldRpcClientEventSourcingStore(table.tableId, rpc.url);
            } else {
                store = new EventSourcingReactLocalClient(table.tableId, eventStream, dataAdapter, postprocess, userInfo.tenant, clientInstance, autocompleteStore, dispatch);
            }


            eventStream.onUploadingSizeChanged.add(async (length: number) => {
                dispatch(updateUploadingQueueLength({tableId: table.tableId, length}));
            });

            eventStream.onDownloadingPartialComplete.add(async (downloadingProcess: number|null) => {
                dispatch(updateDownloadingProgress({tableId: table.tableId,  length: downloadingProcess }));
            });

            stores[table.tableId] = store;
        }

        return stores;
    }, [tables, userInfo]);


    useEffect(()=> {
        //turn off preloading when user open edit form
        if (tables != null && !isEditEntity) {
            for (let table of tables) {
                let preloadQueries: IQuerySpecification[] = [];
                preloadQueries.push(new AllFilter('', undefined, undefined, table.sortDirection, ()=>{}).getQuery(0, table!.sortField));

                for (let filter of table?.filters ?? []) {
                    try {
                        if (filter.users == null || filter.users.includes(userInfo!.login)) {
                            if (filter.version != null && filter.version >= 2) {
                                let query = {
                                    where: filter.where,
                                    orderBy: filter.sortField ?? table?.sortField
                                }
                                preloadQueries.push(query);
                            } else {
                                //TODO: remove QueryFilter usage. make more direct transformation
                                preloadQueries.push(new QueryFilter('', filter, table.sortDirection, ()=>{}, ()=>{}).getQuery(0, table!.sortField));
                            }
                        }
                    } catch (err) {
                        Logger.error('error preloading filter:', filter, serializeError(err, {maxDepth:2}));
                    }
                }

                if (!fullDataCacheEnabled()) {
                    const queryVars = createQueryVars(userInfo);
                    stores[table.tableId].preloadLiveQuerieMonitors(preloadQueries, queryVars);
                }
            }
        }
        
        const initEntityCounts = async () => {
            const updateTasks = tables?.map(table => {
                return dispatch(updateEntityCountAsync({store: stores[table.tableId]}))
            });
            if (updateTasks != null)
                await Promise.all(updateTasks);
        }

        initEntityCounts().catch(err => Logger.error('initEntityCounts failed', serializeError(err, { maxDepth: 2 })));



        return () => {
            for (let [tableId, store] of Object.entries(stores)) {
                Logger.debug(`dispose store ${tableId}`);
                store.dispose();
            }
        }
    }, [stores]);

    return (
      <StoreContext.Provider value={stores!}>
        {props.children}
      </StoreContext.Provider>
    );
  };