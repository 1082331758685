import type { IEntity } from "@core/Models/i-entity";
import { EventSourcingStore } from "../EventSourcingStore";
import { ResultSet } from "./ResultSet";
import { IResultEntry } from "./ResultSet";
import { LiveQueryMonitor, LiveQueryMonitorState } from "./LiveQueryMonitor";
import Logger from "js-logger";

export interface IEventSouringQueryViewState {
    status: 'loading' | 'live' | 'error';
    resultCount: number;
    error?: string;
}

export class LiveQueryView {
    public state: IEventSouringQueryViewState = { status: 'loading', resultCount: 0 };

    private monitor: LiveQueryMonitor;

    private entities: IEntity[];
    private skip: number;
    private limit: number;
    private sortAscending: boolean;
    private store: EventSourcingStore;

    private onArrayUpdateHandlers: Array<(entities: IEntity[]) => void> = [];
    private onIndividualUpdateHandlers: Array<(entities: IEntity[]) => void> = [];
    private onNewStateHandlers: Array<(state: IEventSouringQueryViewState) => void> = [];

    private disposed: boolean = false;

    constructor(store: EventSourcingStore, monitor: LiveQueryMonitor, skip: number, limit: number, sortAscending: boolean) {
        this.store = store;
        this.monitor = monitor;
        this.entities = [];
        this.skip = skip;
        this.limit = limit;
        this.sortAscending = sortAscending;

        this.monitor.onChange(this.onDataChangedHandler);
        this.monitor.onNewState(this.onNewMonitorState);

        if (this.monitor.state == LiveQueryMonitorState.error) {
            this.state = {
                status: "error",
                error: this.monitor.state_text,
                resultCount: 0,
            };
            return;
        }

        this.loadData(this.monitor.result);
    }

    public dispose = () => {
        this.monitor.onChangeRemove(this.onDataChangedHandler);

        this.onArrayUpdateHandlers = [];
        this.onIndividualUpdateHandlers = [];
        this.onNewStateHandlers = [];

        this.disposed = true;
    };

    public getEntities() {
        return this.entities;
    }

    private onDataChangedHandler = (resultSet: ResultSet, changedEntities: Map<string, IEntity>) => {
        this.loadData(resultSet, changedEntities);
    };

    private onNewMonitorState = (state: LiveQueryMonitorState) => {
        if (state == LiveQueryMonitorState.error) {
            this.state = {
                status: "error",
                error: this.monitor.state_text,
                resultCount: 0,
            };
            this.triggerOnNewState(this.state);
        }
    }

    private loadData = (resultSet: ResultSet, changedEntities?: Map<string, IEntity>) => {
        const start_time = performance.now();
        //console.log('[View] start updating');
        const changedIds = changedEntities != null ? Array.from(changedEntities.keys()) : null;
        const sortCriteria = [['orderKey', !this.sortAscending], ['id', true]] as [keyof IResultEntry, boolean][];
        const resultCount = resultSet.count();
        const refs = resultSet.take(this.skip, this.limit, sortCriteria);

        //console.log(`[View] refs loaded ${performance.now() - start_time} ms`);
        var extendedCache = new Map<string, IEntity>(changedEntities);
        for (let e of this.entities)
            if (!extendedCache.has(e.id))
                extendedCache.set(e.id, e);

        let needLoad = [];
        for (let e of refs) {
            if (!extendedCache.has(e.id))
                needLoad.push(e.id);
        }

        //console.log(`[View] load task created, count:${needLoad.length}. ${performance.now() - start_time} ms`);
        if (needLoad.length > 0) {
            this.store.bulkGet(needLoad)
                .then(entities => {
                    //console.log(`[View] entities loaded ${performance.now() - start_time} ms`);
                    for (let entity of entities) {
                        if (entity != null) {
                            extendedCache.set(entity.id, entity);
                        }
                    }

                    this.updateEntities(refs, changedIds, extendedCache, resultCount);

                    //console.log(`[View] entities updated ${performance.now() - start_time} ms`);
                })
                .catch(err => {
                    Logger.error("[LiveQueryView] loadData store.bulkGet failed", err);
                });
        } else {
            this.updateEntities(refs, changedIds, extendedCache, resultCount);

            //console.log(`[View] entities updated from cache. ${performance.now() - start_time} ms`);
        }
    };

    private updateEntities = (refs: IResultEntry[], changedIds: string[] | null, data: Map<string, IEntity>, resultCount: number) => {
        let setChanged = false;
        if (changedIds != null && refs.length === this.entities.length) {
            for (let i = 0; i < this.entities.length; i++) {
                if (this.entities[i].id !== refs[i].id) {
                    setChanged = true;
                    break;
                }
            }
        } else {
            setChanged = true;
        }

        this.entities = refs.map(x => data.get(x.id)).filter(isDefined);
        if (this.entities.length != refs.length)
            Logger.debug("[LiveQuerView] refs and db are not consistent. Some entities was deleted during update");

        if (setChanged)
            this.triggerOnArrayUpdate(this.entities);
        else {
            let changedIdsSet = new Set(changedIds);
            let changedRefs = refs
                .filter(x => changedIdsSet.has(x.id))
                .map(x => data.get(x.id)!);
            if (changedRefs.length > 0)
                this.triggerOnIndividualUpdate(changedRefs);
        }

        if (this.monitor.state === LiveQueryMonitorState.live)
            if (this.state.status !== 'live' || this.state.resultCount != resultCount) {
                this.state = { status: 'live', resultCount: resultCount };
                this.triggerOnNewState(this.state);
            }
    };

    public onArrayUpdate = (handler: (entities: IEntity[]) => void): void => {
        this.onArrayUpdateHandlers.push(handler);
    };
    public onArrayUpdateRemove = (handler: (entities: IEntity[]) => void): void => {
        this.onArrayUpdateHandlers = this.onArrayUpdateHandlers.filter(h => h !== handler);
    };
    private triggerOnArrayUpdate = (entities: IEntity[]) => {
        for (let h of this.onArrayUpdateHandlers.slice(0))
            h(entities);
    };

    public onIndividualUpdate = (handler: (entities: IEntity[]) => void): void => {
        this.onIndividualUpdateHandlers.push(handler);
    };
    public onIndividualUpdateRemove = (handler: (entities: IEntity[]) => void): void => {
        this.onIndividualUpdateHandlers = this.onIndividualUpdateHandlers.filter(h => h !== handler);
    };
    private triggerOnIndividualUpdate = (entities: IEntity[]) => {
        for (let h of this.onIndividualUpdateHandlers.slice(0))
            h(entities);
    };

    public onNewState = (handler: (state: IEventSouringQueryViewState) => void): void => {
        this.onNewStateHandlers.push(handler);
    };
    public onNewStateRemove = (handler: (state: IEventSouringQueryViewState) => void): void => {
        this.onNewStateHandlers = this.onNewStateHandlers.filter(h => h !== handler);
    };
    private triggerOnNewState = (state: IEventSouringQueryViewState): void => {
        for (let h of this.onNewStateHandlers.slice(0))
            h(state);
    };
}

//type guard
function isDefined<T>(item: T | undefined): item is T {
    return item !== undefined;
}