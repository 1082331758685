import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './CommentsInput.module.scss'
import {DateTime} from 'luxon';
import {ICrmCommentMessage} from '@core/Models/i-comment-message';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { extractComments } from 'src/Utils/commentsUtils';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@core/Redux/hooks';
import { selectUserInfo } from '@core/Redux/store';
import { StringInput } from '../StringInput/StringInput';
import { ICrmField } from '@core/Models/autogenerated/tenantConfig.models';

export interface ICommentsInputProps {
    entityId?: string;
    field: ICrmField;
    value: ICrmCommentMessage[];
    onValueChanged: (value: ICrmCommentMessage[]) => void;
    editState: any;
    setEditState: React.Dispatch<any>;
    onOpenComments: (fieldId: string) => void;
    style?: CSSProperties;
    readonly?: boolean;
}

export function CommentsInput(props: ICommentsInputProps) {
    const { t } = useTranslation();
    const [ inputValue, setInputValue ] = useState<string|undefined>('');
    const navigator = useNavigate();
    const userInfo = useAppSelector(selectUserInfo);

    const currentComments = useMemo(()=>extractComments(props.value), [props.value]);

    const currentCommentId = props.editState[props.field.id];

    useEffect(()=> {
        props.setEditState( (prev: any) => {
            if (prev[props.field.id] === undefined)
                return { ...prev, [props.field.id] : uuidv4() };
            else {
                let msg = currentComments.find(x => x.id === prev[props.field.id]);
                setInputValue(msg?.message ?? '');
                return prev;
            }
        });
    }, [props.field.id]);

    const onClick = () => {
        props.onOpenComments(props.field.id);
    };

    const onChange = useCallback( (value: string | null) => {
        let comments: ICrmCommentMessage[] = [];
        if (value) {
            comments  = [...currentComments];
            let msg = comments.find(x => x.id === currentCommentId);
            if (msg !== undefined) {
                msg.message = value;
            } else {
                const commentItem = {
                    id: currentCommentId ?? uuidv4(),
                    author: userInfo?.login,
                    date: DateTime.utc().toUnixInteger(),
                    message: value
                } as ICrmCommentMessage;

                comments.push(commentItem);
            }
        } else {
            comments  = currentComments.filter(x => x.id !== currentCommentId);
        }

        props.onValueChanged(comments);
        setInputValue(value ?? '');
    }, [props.value, currentCommentId]);

    if (currentCommentId === undefined)
        return null;

    return <div className={styles.commentsInput} style={props.style}>
        <StringInput
            initialValue={inputValue}
            placeholder={props.field.placeholder}
            onChanged={onChange}
            style={props.style}
            readonly={props.readonly}
        />
        {props.entityId != null && !props.readonly &&
            <a onClick={onClick}>{t("show-all-comments")}({currentComments.length})</a>
        }
    </div>;
}
