import axios, { AxiosResponse } from 'axios';
import { serverUrl } from '@platform/config';
import { IGetLastExternalRequest } from '@core/Models/autogenerated/operation.models';

const endpoint = `${serverUrl}/api/ExternalEvent`;

type IExternalEventMandatory = {
    crm_tenant: string;
    crm_eventtype: string;
    // Add other known fields here
};

export interface IGetLastExternalStreamReq {
    filter: any;
    fromDate?: Date;
}

// Define a type that allows any number of additional string properties
export type IExternalEvent = IExternalEventMandatory & {
    [key: string]: unknown;
};

export async function externalEventApiAdd(req: IExternalEvent): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/Add`, JSON.stringify(req));
    return resp;
}

export async function externalEventApiGetLast(req: IGetLastExternalRequest): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/GetLast`, JSON.stringify(req));
    return resp;
}

export async function* externalEventApiGetLastStream(req: IGetLastExternalStreamReq): AsyncGenerator<any, void, unknown> {
    const limit = 1000;
    let lastServerId = -1;

    while (true) {
        const response = await externalEventApiGetLast({
            filter: req.filter,
            fromServerId: lastServerId + 1,
            fromDate: req.fromDate,
            limit,
        });

        const events = response.data;

        if (events.length === 0) {
            break;
        }

        for (const event of events) {
            lastServerId = event.id;
            yield event;
        }
    }
}