import { CrmFieldViewType, CrmFieldDataTransormation } from "@core/Models/autogenerated/tenantConfig.models";
import { VisibilityOnList } from "@core/Models/tenantConfig.models";

export class TenantConfigValidator {

    public static processField(field: any, simple: boolean = false): string | undefined {
        if (field == null || typeof field != "object") {
            return `Incorrect format for input: field must be an object`;
        }

        if (field.id == null) {
            return `Incorrect format for input: 'id' not set`;
        }
        if (typeof field.id != "string") {
            return `Incorrect 'id' value: 'id' must be a string`;
        }

        if (field.caption == null) {
            return `Incorrect format for input: 'caption' not set`;
        }
        if (typeof field.caption != "string") {
            return `Incorrect 'caption' value: 'caption' must be a string`;
        }

        if (simple && [CrmFieldViewType.Array, CrmFieldViewType.Comments, CrmFieldViewType.Timeline, CrmFieldViewType.Unknown].includes(field.viewType)) {
            return `Incorrect 'viewType' value: 'viewType' of inner field can't be '${field.viewType}'`;
        }

        if ([CrmFieldViewType.Array, CrmFieldViewType.Comments, CrmFieldViewType.Timeline].includes(field.viewType) && field.visibilityOnList == VisibilityOnList.Title) {
            return `Incompatible 'viewType' and 'visibilityOnList': `
                + `if 'viewType' is '${field.viewType}', then 'visibilityOnList' can't be '${VisibilityOnList.Title}'`
        }

        if (field.viewType == CrmFieldViewType.Array) {
            if (field.fields == null) {
                return `Incorrect format for input: if 'viewType' is '${CrmFieldViewType.Array}', then 'fields' should be set`;
            }
            if (!Array.isArray(field.fields)) {
                return `Incorrect 'fields' value: 'fields' should be an array`;
            }
            if (field.fields.length == 0) {
                return `Incorrect 'fields' value: if 'viewType' is '${CrmFieldViewType.Array}', then 'fields' can't be empty`;
            }
            for (let i = 0; i < field.fields.length; i++) {
                const innerField = field.fields[i];
                if (typeof innerField !== "object" || innerField === null) {
                    return `Incorrect 'fields' value at index ${i}: 'fields' item must be a field object;`;
                }
                const innerFieldError = this.processField(innerField, simple=true);
                if (innerFieldError) {
                    return `Incorrect 'fields' value at index ${i}: ${innerFieldError};`;
                }
            }            
        }

        if (field.viewType == CrmFieldViewType.Combobox) {
            if (field.options == null) {
                return `Incorrect format for input: if 'viewType' is '${CrmFieldViewType.Combobox}', then 'options' should be set`;
            }
            if (!Array.isArray(field.options)) {
                return `Incorrect 'options' value: 'options' should be an array`;
            }
            if (field.options.length == 0) {
                return `Incorrect 'options' value: if 'viewType' is '${CrmFieldViewType.Combobox}', then 'options' can't be empty`;
            }
            for (let i = 0; i < field.options.length; i++) {
                const option = field.options[i];
                if (typeof option != "object" || option == null) {
                    return `Incorrect 'options' value at index ${i}: 'options' item must be an object with 'value' and 'label' fields`;
                }
                if (option.value == null || typeof option.value != "string") {
                    return `Incorrect 'options' value at index ${i}: 'options' item must have a 'value' field and it must be a string`;
                }
                if (option.label == null || typeof option.label != "string") {
                    return `Incorrect 'options' value at index ${i}: 'options' item must have a 'label' field and it must be a string`;
                }
            }
        }

        if (field.visibilityOnList != null && !Object.values(VisibilityOnList).includes(field.visibilityOnList)) {
            return `Incorrect 'visibilityOnList' value: 'visibilityOnList' must be one of the following strings:\n'`
                + Object.values(VisibilityOnList).join(`',\n'`) + `'`;
        }

        if (field.placeholder != null && typeof field.placeholder != "string") {
            return `Incorrect 'placeholder' value: 'placeholder' must be a string`;
        }

        if (field.textEllipsis != null && typeof field.textEllipsis != "number") {
            return `Incorrect 'textEllipsis' value: 'textEllipsis' must be a number`;
        }

        if (field.autocomplete != null && typeof field.autocomplete != "boolean") {
            return `Incorrect 'autocomplete' value: 'autocomplete' must be a boolean`;
        }

        if (field.readonly != null && typeof field.readonly != "boolean") {
            return `Incorrect 'readonly' value: 'readonly' must be a boolean`;
        }

        if (field.hideOnCreateDialog != null && typeof field.hideOnCreateDialog != "boolean") {
            return `Incorrect 'hideOnCreateDialog' value: 'hideOnCreateDialog' must be a boolean`;
        }

        if (field.dataTransformations != null) {
            if (!Array.isArray(field.dataTransformations)) {
                return `Incorrect 'dataTransformations' value: 'dataTransformations' must be an array`;
            }

            for (const transformation of field.dataTransformations) {
                if (transformation == null || !Object.values(CrmFieldDataTransormation).includes(transformation)) {
                    return `Incorrect 'dataTransformations' value: each 'dataTransformations' item must be one of the following strings:\n'`
                        + Object.values(CrmFieldDataTransormation).join(`',\n'`) + `'`;
                }
            }
        }

        if (field.plugins != null) {
            if (!Array.isArray(field.plugins)) {
                return `Incorrect 'plugins' value: 'plugins' must be an array of strings`;
            }

            for (const plugin of field.plugins) {
                if (plugin == null || typeof plugin != "string") {
                    return `Incorrect 'plugins' value: each 'plugins' item must be a string`;
                }
            }
        }

        if (field.style != null) {
            if (typeof field.style != "object") {
                return `Incorrect 'style' value: 'style' must be an object`;
            }

            if (field.style.maxWidth != null && typeof field.style.maxWidth != "string") {
                return `Incorrect 'style' value: 'maxWidth' field of 'style' must be a string`;
            }

            if (field.style.wordWrap != null && typeof field.style.wordWrap != "string") {
                return `Incorrect 'style' value: 'wordWrap' field of 'style' must be a string`;
            }

            if (field.style.whiteSpace != null && typeof field.style.whiteSpace != "string") {
                return `Incorrect 'style' value: 'whiteSpace' field of 'style' must be a string`;
            }
        }
    }
}