//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CrmFieldDefault, ICrmFieldStyle, ICrmFilter, VisibilityOnList } from '../tenantConfig.models';

export interface ITenantSelector
{
	useDefaultTenant: boolean;
	tenant?: string;
}
export interface ITenantConfig
{
	tables: ITableConfig[];
	telephony: ITelephonyConfig;
	esnode: IEsNodeConfig;
	language: string;
	tenantName: string;
}
export interface ITableConfig
{
	tableId: string;
	tableName: string;
	fields: ICrmField[];
	sortField: string;
	sortDirection: CrmSortDirection;
	autoOpenField?: string;
	filters: ICrmFilter[];
}
export interface IEsNodeConfig
{
	enabled: boolean;
	url: string;
}
export interface ICrmField
{
	id: string;
	caption: string;
	placeholder?: string;
	textEllipsis?: number;
	autocomplete?: boolean;
	readonly?: boolean;
	hideOnCreateDialog?: boolean;
	keywords?: boolean;
	viewType: CrmFieldViewType;
	dataTransformations?: CrmFieldDataTransormation[];
	plugins?: string[];
	options?: ICrmValueOption[];
	style?: ICrmFieldStyle;
	visibilityOnList?: VisibilityOnList;
	fields?: ICrmField[];
	regExp?: string;
	default?: CrmFieldDefault | string;
	hidden?: boolean;
}
export interface ICrmValueOption
{
	value: string;
	label: string;
}
export interface ITelephonyConfig
{
	providers: ITelephonyProviderConfig[];
}
export interface ITelephonyProviderConfig
{
	name: string;
	provider: string;
	users: ITelephonyProviderUser[];
}
export interface ITelephonyProviderUser
{
	login: string;
	extensionUserName: string;
	extensionNumber: string;
}
export enum CrmFieldViewType {
	Timeline = "Timeline",
	Comments = "Comments",
	String = "String",
	Decimal = "Decimal",
	Date = "Date",
	Time = "Time",
	YesNo = "YesNo",
	Combobox = "Combobox",
	MultiString = "MultiString",
	Url = "Url",
	Phone = "Phone",
	Array = "Array",
	Unknown = "Unknown"
}
export enum CrmSortDirection {
	Asc = "Asc",
	Desc = "Desc"
}
export enum CrmFieldDataTransormation {
	FromYmdToUnixTimestamp = "FromYmdToUnixTimestamp"
}
