import { IEsNodeConfig } from "./Models/autogenerated/tenantConfig.models";

export function eventSourcingRpc(esnode?: IEsNodeConfig): IEsNodeConfig {
    //todo: move this setting to .env.*
    if (process.env.NODE_ENV == "production" && esnode != null)
        return esnode;

    let enabled = false;
    try {
        const enabled_str = localStorage.getItem('eventSourcingRpcEnabled');
        enabled = enabled_str && JSON.parse(enabled_str as string);
    } catch {
    }

    const url = localStorage.getItem("eventSourcingRpcUrl");
    if (enabled && url) {
        return {enabled, url};
    }
    return {enabled: false, url: ""};
}
