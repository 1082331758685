import {CSSProperties, MutableRefObject, useEffect, useMemo, useState} from 'react';
import styles from "./ComboboxInput.module.scss";
import { t } from 'i18next';
import { InvalidSpan } from '../InvalidHint/InvalidHint';
import { Select } from 'antd';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { registerComponent } from '@core/Plugins/pluginManager';
import { ICrmValueOption } from '@core/Models/autogenerated/tenantConfig.models';

export interface IComboboxInputProps {
    initialValue?: string | null;
    options?: ICrmValueOption[];
    placeholder?: string;
    setIsValid?: (isValid: boolean) => void;
    onChanged?: (value: string | null) => void;
    valueRef?: MutableRefObject<string | null>;
    className?: string
    style?: CSSProperties;
    readonly?: boolean;
    validation?: (value: any) => boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    disableClear?: boolean;
    multiple?: boolean;
}

export const ComboboxInput = registerComponent(coreUiComponentDescriptions.ComboboxInput, _ComboboxInput);

function _ComboboxInput(props: IComboboxInputProps) {
    const isValidValue = (value: any) => {
        if (props.validation) {
            return props.validation(value);
        }

        if (value == null)
            return true;

        if (props.multiple) {
            if (!Array.isArray(value))
                return false;

            if (value.length == 0)
                return true;
            
            return value.every(e => props.options?.find(o => o.value == e.toString()) != null);
        } else {
            if (value === "" || (Array.isArray(value) && value.length == 0)) {
                return true;
            }
        
            if (typeof value != "string" && typeof value != "number") {
                return false;
            }
        
            return props.options?.find(x => x.value == value.toString()) != null;
        }
    }

    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));
    const [inputValue, setInputValue] = useState<string>(extractString(props.initialValue));

    useEffect(() => {
        setIsValid(isValidValue(props.initialValue));
    }, [props.initialValue, props.options]);

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(isValid);
        }
    }, [isValid]);
    
    //antd select does not support abstract values. only string/number. store it as keys
    const options = useMemo(() => {
        return props.options?.map(x => ({label: x.label, value: JSON.stringify(x.value)})) ?? [];
    }, [props.options]);

    const onChange = (e: any) => {
        if (e === undefined) {
            e = null;
        }

        //antd rstore values back from keys
        if (Array.isArray(e))
            e = (e as []).map(v => JSON.parse(v))
        else
            e = JSON.parse(e);

        setIsValid(true);
        setInputValue(e);
        props.onChanged?.(e);
        if (props.valueRef) {
            props.valueRef.current = e;
        }
    }

    let defaultValue = props.options?.find(x => x.value == inputValue);
    if (defaultValue == null && inputValue !== "") {
        defaultValue = {
            value: inputValue,
            label: inputValue,
        } as ICrmValueOption;
    }

    return <div onClick={e => e.stopPropagation()}>
        <Select
            mode={props.multiple ? "multiple" : undefined}
            options={options}
            defaultValue={defaultValue}
            placeholder={isValid ? props.placeholder : t("error")}
            onChange={onChange}
            disabled={props.readonly || props.disabled}
            className={styles.select}
            allowClear={!props.disableClear}
            status={isValid ? undefined : "error"}
            autoFocus={props.autoFocus}
        />
        {isValid ||
            <InvalidSpan/>
        }
    </div>;
}

function extractString(value: any) {
    if (value == null) {
        return "";
    }

    if (typeof value == "string" || typeof value == "number") {
        return value.toString();
    }

    return "";
}
