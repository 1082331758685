import _ from 'lodash';

import { v4 as uuidv4 } from 'uuid';
import { BaseTool, CompositeTool, IApiForAIAgent } from "./ai-api";
import { ListFieldsTool } from './fieldsManager';
import { BaseFilter, IFilter, QueryFilter, QueryFilterV2 } from 'src/App/Pages/OrdersPage/OrderFilters/OrderFilters';
import Logger from 'js-logger';
import { CrmSortDirection } from '@core/Models/autogenerated/tenantConfig.models';
import { ICrmFilter } from '@core/Models/tenantConfig.models';

const getFilterManagerPrompt = async (api:IApiForAIAgent) => {
    const currentTableId = api.getCurrentSelectedTable()?.tableId;

    return `You are an AI CRM filters (also known as tabs) management tool. 
There is no conversation with user, so all information must be used as is. 
You need to use provided tools to solve the task. 
filter structure is: 
{ 
    "id": <filter id as string>, 
    "caption": <filter caption. User see this as tab name>, 
    "conditions": <array of conditions for data filtration with structure described below. In new version of fields "where" used in place of this with different format. Format of "where" not described here and you use old format>,
    "where": <conditions in new version of filter structure. Not described here. Do not use or modify this. "null" by default>,
    "users": <array of strings: user logins that can use this filter. Default "null" - means for all users>,
    "fields": <array of strings: field ids. when user use this filter, view display this subset of fields. Default "null" - means all show fields>
    ... //other fields without description. preserve this intact when updating
}.
List of available filter formats:
1. format of range condition for numeric types (Not applicable for dates):
{ 
    "fieldName": <Field id (string)>, 
    "valueType": "Range", 
    "rangeValue": { 
        "lt": <Value strictly less than (optional)>, 
        "gte": <Value greater than or equal to (optional)>, 
        "ltValueType": "Numeric", 
        "gteValueType": "Numeric" 
    } 
}.
2. format of range condition for date types relative to currend day (do not use it for absolute date range!):
{ 
    "fieldName": <Field id (string)>, 
    "valueType": "Range", 
    "rangeValue": { 
        "lt": <Optional integer offset. Add this value to the start of day to get the desired "strictly less than" time>, 
        "gte": <Optional integer offset. Add this value to the start of day to get the desired "greater than or equal to" time>, 
        "ltValueType": "StartOfDayPlusDayDiff", //this type indicate that 'lt' condition is relative to current time 
        "gteValueType": "StartOfDayPlusDayDiff" //this type indicate that 'gte' condition is relative to current time 
    } 
}.
Here's an example of a 'tomorrow' condition:
{ 
    "fieldName": <Field id (string)>, 
    "valueType": "Range", 
    "rangeValue": { 
        "lt": "2", 
        "gte": "1", 
        "ltValueType": "StartOfDayPlusDayDiff", 
        "gteValueType": "StartOfDayPlusDayDiff" 
    } 
}.
3. format of range condition for date types with absolute date range:
Not implemented
4. format of field == value condition:
{ 
    "fieldName": <Field id (string)>, 
    "value": <value to be equal>, 
    "valueType": "Constant" 
}.
5. format of field contained in set of values:
{ 
    "values": [ <value1>, <value2>, ... ],
    "fieldName": <Field id (string)>, 
    "valueType": "Constant" 
}.
6. format of field of string type contains substring:
{ 
    "value": <substring>,
    "fieldName": <Field id (string)>, 
    "valueType": "Substring" 
}.
7. format of field is not empty:
{ 
    "fieldName": <Field id (string)>, 
    "valueType": "NonEmpty" 
}.

Notes:
- If there is no suitable format for filter, return appropriate error message.
- Every table uses its own set of filters.
- Each filter work with data from its table.

Table currently opened by the user is { "tableId": "${currentTableId}"}
The summary information on available fields(in short format) in CRM:${await new ListFieldsTool(api, true).run("")}.
The summary information on available filters(in short format) in CRM:${await new ListFiltersTool(api).run("")}.`;
}


export class FiltersManagerTool extends CompositeTool {
    name: string = "filters_manager";
    description: string = 
`This function is useful when a user wants to manage CRM filters. 
Filters represent filtered data view. User use filters when need to observe data filtered and sorted by some conditions. 
Do not try to use this function if user wants to change cells or rows or use conditional behaviour on unfiltered data. 
The input is a text with a human-readable description of the filter modification task. 
`;
    
    toolkit: BaseTool[];

    public getPrompt = (): Promise<string> => getFilterManagerPrompt(this.api);

    constructor(api: IApiForAIAgent, access_token : string) {
        super(api, access_token, 'gpt-4o');

        this.toolkit = [
            new ListFieldsTool(this.api, true),
            new ListFiltersTool(this.api),
            new AddFilterTool(this.api),
            new DeleteFilterTool(this.api),
            new UpdateFilterTool(this.api),
            new GetFilterInfoTool(this.api),
        ];
    }

    async run(query: string) : Promise<string> {
        console.log(`*FiltersManagerTool ${query}`);

        const agent = await this.createAgent();

        const response = await agent.invoke({
            input: query,
        });

        return response.output;
    }
}

function checkFilterStructure(filter: ICrmFilter, idRequired: boolean): string | null {
    if (idRequired && !filter.id)
        return "Incorrect format for input: 'id' not set";
    if (!filter.caption)
        return "Incorrect format for input: 'caption' not set";
    if (!filter.conditions && !filter.where)
        return "Incorrect format for input: 'conditions' or 'where' not set";
    if (filter.conditions != null && !Array.isArray(filter.conditions))
        return "Incorrect format for input: 'conditions' must be array";

    let queryFilter: IFilter;
    if (filter.conditions != null)
        queryFilter= new QueryFilter('', filter, CrmSortDirection.Asc, ()=>{}, ()=>{});
    else if (filter.where != null)
        queryFilter = new QueryFilterV2('', filter, CrmSortDirection.Asc, ()=>{}, ()=>{});
    else {
        Logger.error("Implementation error: Filter checking failed");
        throw new Error('Not implemented');
    }

    try {
        let _ = queryFilter.getQuery(0, "createdAt");
    }
    catch(err: any) {
        return err.toString();
    }

    return null;
}

export class AddFilterTool extends BaseTool {
    name: string = "add_filter";
    description: string =
`useful for when you need add filter view to CRM.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filter": <filter config according filter structure>, 
}.`;

    async run(query: string): Promise<string> {
        try {
            console.log(`*AddFilterTool ${query}`);
            let task : {tableId: string, filter: ICrmFilter};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". You submitted incorrect data. Make sure your input correctly formatted JSON";
            }

            let error = checkFilterStructure(task.filter, false);
            if (error)
                return error;
    
            task.filter.aiGenerated = true;
            task.filter.id = uuidv4();


            let oldFilters = this.api.getFilters(task.tableId) ?? [];

            if (oldFilters.find(x => x.caption.trim() === task.filter.caption.trim()))
                return `Filter with caption '${task.filter.caption}' is already exist in CRM`;

            let newFilters = [...oldFilters, task.filter];

            await this.api.saveFilters(task.tableId, newFilters);
            this.api.selectFilter(task.tableId, task.filter.id);

            return "Filter successfully added.";
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}

export class DeleteFilterTool extends BaseTool {
    name: string = "delete_filter";
    description: string =
`useful for when you need to delete filter view from CRM.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filterId": <target filter id>
}.`;

    async run(query: string): Promise<string> {
        try {
            console.log(`*DeleteFilterTool ${query}`);
            let task : {tableId: string, filterId: string};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". You submitted incorrect data. Make sure your input correctly formatted JSON";
            }

            let oldFilters = this.api.getFilters(task.tableId) ?? [];
    
            let newFilters: any[] = [];
            let filterFounded = false;

            for (let f of oldFilters) {
                if (f.id !== task.filterId) {
                    newFilters.push(f);
                } else {
                    filterFounded = true;
                }
            }

            if (!filterFounded)
                return `Error: filter was not deleted because given id ${task.filterId} not found. You can use list_filters to look at available filters.`;

            await this.api.saveFilters(task.tableId, newFilters);
            return "filter deleted successfully";
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}

export class GetFilterInfoTool extends BaseTool {
    name: string = "get_filter_info";
    description: string =
`useful for when you need to get full information about specific filter.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filterId": <target filter id>
}.
Output is a JSON with filter configuration.`;
    async run(query: string) : Promise<string> {
        try {
            console.log(`*GetFilterInfoTool ${query}`);

            let task : {tableId: string, filterId: string};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". You submitted incorrect data. Make sure your input correctly formatted JSON";
            }

            let filters = this.api.getFilters(task.tableId) ?? [];

            const result = filters.find(x => x.id === task.filterId);
            if (!result)
                return `Error: can not read filter properites because filter with id '${task.filterId}' not found in table with id '${task.tableId}'. You can use list_filters to look at available filters.`; 

            return JSON.stringify(result);
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class UpdateFilterTool extends BaseTool {
    name: string = "update_filter";
    description: string =
`useful for when you need to update filter properites in CRM. Make sure you have full current filter configuration. Preserve all properites intact except updated fields.
Input should be a JSON object in the following format: 
{ 
  "tableId": <target table ID>, 
  "filter": <new filter config according filter structure, id must be existing filter id>, 
}`;
    async run(query: string) : Promise<string> {
        try {
            console.log(`*UpdateFilterTool ${query}`);

            let task : {tableId: string, filter: ICrmFilter};

            try {
                task = JSON.parse(query);
            }
            catch (error: any) {
                return "Exception: " + error.toString() + ". You submitted incorrect data. Make sure your input correctly formatted JSON";
            }

            let error = checkFilterStructure(task.filter, true);
            if (error)
                return error;

            task.filter.aiGenerated = true;

            let oldFilters = this.api.getFilters(task.tableId) ?? [];

            let newFilters : any[] = [];
            let filterFounded = false;

            for(let f of oldFilters) {
                if (f.id === task.filter.id) {
                    newFilters.push(task.filter);
                    filterFounded = true;
                } else {
                    newFilters.push(f);
                }
            }

            if (!filterFounded) 
                return `Error: filter was not updated because filter with id '${task.filter.id}' not found in table with id '${task.tableId}'. You can use list_filters to look at available filters.`;

            this.api.saveFilters(task.tableId, newFilters);
            return "filter updated successfully";
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}

export class ListFiltersTool extends BaseTool {
    name: string = "list_filters";
    description: string =
        "useful for when you need to get brief summary information about all available filters in CRM. Input is empty. Output is existing filters represented as array of objects of partial filter structure.";
    async run(query: string) : Promise<string> {
        try {
            console.log(`*ListFilters ${query}`);

            let config = this.api.getOrderConfig();
            if (!config)
                return "[]";

            let result = [];
            for (let tableConfig of config.tables) {
                let filters = _.map(tableConfig.filters, (x: ICrmFilter) => { return {id: x.id, caption: x.caption} });
                result.push({
                    tableId: tableConfig.tableId,
                    tableName: tableConfig.tableName,
                    filters: filters
                }) 
            }
            return JSON.stringify(result);
        }
        catch (error : any) {
            return "Exception: " + error.toString();
        }
    }
}