import { ICrmArrayElement } from "@core/Models/i-array-element";
import { ICrmCommentMessage } from "@core/Models/i-comment-message";
import { registerComponent } from "@core/Plugins/pluginManager";
import { coreUiComponentDescriptions } from "@pluginShared/core-ui-api";
import { CSSProperties } from "react";
import { CheckboxInput } from "@core/VisualComponents/Inputs/CheckboxInput/CheckboxInput";
import { ComboboxInput } from "@core/VisualComponents/Inputs/ComboboxInput/ComboboxInput";
import { CommentsInput } from "@core/VisualComponents/Inputs/CommentsInput/CommentsInput";
import { DateInput } from "@core/VisualComponents/Inputs/DateInput/DateInput";
import { StringInput } from "@core/VisualComponents/Inputs/StringInput/StringInput";
import { TimeInput } from "@core/VisualComponents/Inputs/TimeInput/TimeInput";
import { ArrayEditorInner } from "../ArrayComponents/ArrayEditorInner/ArrayEditorInner";
import { DecimalInput } from "@core/VisualComponents/Inputs/DecimalInput/DecimalInput";
import { InvalidTypeInput } from "@core/VisualComponents/Inputs/InvalidTypeInput/InvalidTypeInput";
import { UrlInput } from "@core/VisualComponents/Inputs/UrlInput/UrlInput";
import { IEntityData } from "@core/Models/i-entity";
import { PhoneInput } from "@core/VisualComponents/Inputs/PhoneInput/PhoneInput";
import { InputValidator } from "@core/VisualComponents/Inputs/InputValidator/InputValidator";
import { ICrmField, CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models";

export interface IOrderFieldEditorProps {
    onChanged: (value: string | number | boolean | ICrmArrayElement[] | null) => void;
    field: ICrmField;
    value: any;
    entityId?: string;
    entityData: IEntityData;
    editState: any;
    setEditState: React.Dispatch<any>;
    setIsValid: (isValid: boolean) => void;
    autocompleteValues?: string[];
    onOpenComments: (fieldId: string) => void;
    style?: CSSProperties;
    readonly?: boolean;
    tableId: string; // Do not delete! Required for plugins!
    isCreationMode?: boolean; // Do not delete! Required for plugins!
}

export const OrderFieldEditor = registerComponent(coreUiComponentDescriptions.OrderFieldEditor, _OrderFieldEditor);

function _OrderFieldEditor(props: IOrderFieldEditorProps) {
    const { field, value } = props;
    const readonly = props.readonly || field.readonly;

    const onValueChanged = (v: any) => {
        if (readonly || isEqual(value, v)) {
            return;
        }

        props.onChanged(v);
    }

    const viewType = field.viewType;
    switch (viewType) {
        case CrmFieldViewType.Time:
            return <TimeInput
                initialValue={value as string}
                placeholder={field.placeholder}
                onChanged={onValueChanged}
                setIsValid={props.setIsValid}
                readonly={readonly}
                style={props.style}
                validation={value => InputValidator.validateByField(value, field)}
            />;
        case CrmFieldViewType.Date:
            return <DateInput
                initialValue={value as number}
                placeholder={field.placeholder}
                onChanged={onValueChanged}
                setIsValid={props.setIsValid}
                readonly={readonly}
                style={props.style}
                validation={value => InputValidator.validateByField(value, field)}
            />;
        case CrmFieldViewType.YesNo:
            return <CheckboxInput
                initialValue={value as boolean}
                setIsValid={props.setIsValid}
                onChanged={onValueChanged}
                readonly={readonly}
                style={props.style}
                validation={value => InputValidator.validateByField(value, field)}
            />;
        case CrmFieldViewType.Combobox:
            return <ComboboxInput
                initialValue={value as string}
                options={field.options!}
                placeholder={field.placeholder}
                setIsValid={props.setIsValid}
                onChanged={onValueChanged}
                readonly={readonly}
                style={props.style}
                validation={value => InputValidator.validateByField(value, field)}
            />;
        case CrmFieldViewType.Timeline:
        case CrmFieldViewType.Comments:
            return <CommentsInput
                entityId={props.entityId}
                field={field}
                value={value as ICrmCommentMessage[]}
                onValueChanged={onValueChanged}
                editState={props.editState}
                setEditState={props.setEditState}
                onOpenComments={props.onOpenComments}
                readonly={readonly}
                style={props.style}
            />;
        case CrmFieldViewType.Array:
            return <ArrayEditorInner
                values={value}
                onChanged={props.onChanged}
                setIsValid={props.setIsValid}
                readonly={readonly}
                saveIfInvalid
                narrow
                tableId={props.tableId}
                field={field}
                reversed
            />
        case CrmFieldViewType.Decimal:
            return <DecimalInput
                initialValue={value}
                placeholder={field.placeholder}
                onChanged={onValueChanged}
                setIsValid={props.setIsValid}
                readonly={readonly}
                validation={value => InputValidator.validateByField(value, field)}
            />;
        case CrmFieldViewType.String:
        case CrmFieldViewType.MultiString:
            return <StringInput
                initialValue={value}
                placeholder={field.placeholder}
                autocompleteValues={props.autocompleteValues}
                onChanged={onValueChanged}
                setIsValid={props.setIsValid}
                style={props.style}
                readonly={readonly}
                type={viewType == CrmFieldViewType.String ? "text" : "textarea"}
            />;
        
        case CrmFieldViewType.Url:
            return <UrlInput
                initialValue={value}
                placeholder={field.placeholder}
                onChanged={onValueChanged}
                setIsValid={props.setIsValid}
                style={props.style}
                readonly={readonly}
                validation={value => InputValidator.validateByField(value, field)}
            />;
        case CrmFieldViewType.Phone:
            return <PhoneInput
                initialValue={value}
                placeholder={field.placeholder}
                autocompleteValues={props.autocompleteValues}
                onChanged={onValueChanged}
                setIsValid={props.setIsValid}
                style={props.style}
                readonly={readonly}
            />;
        case CrmFieldViewType.Unknown:
        default:
            return <InvalidTypeInput/>;
    }
}

function isEqual(v1: any, v2: any) {
    if (v1 === "") {
        v1 = null;
    }

    if (v2 === "") {
        v2 = null;
    }

    return v1 == v2;
}
