import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Logger from 'js-logger';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { AxiosError } from 'axios';
import { getValueFromLocalStorage, setValueToLocalStorage } from '@core/Hooks/use-local-storage';
import {
    accessTokenKey,
    clientInstanceIdKey,
    fakeUserNameKey,
    dbSchemaHashKey,
    dbTenantKey
} from '@core/Constants/app-storage-keys';
import { getTenantConfig, userApiGetUserInfo } from '@core/Api/user-api';
import { ICrmUserInfo } from '@core/Models/autogenerated/user.models';
import { IDataBase } from 'jsstore';
import { v3 as murmurhash } from 'murmurhash';
import { db, dbName } from '@core/JsStore/idb';
import { createKvPairsTable } from '@core/JsStore/tableDescriptions/create-kv-pairs-table';
import { createAutocompleteTable } from '@core/JsStore/tableDescriptions/create-autocomplete-table';
import { mapJsStoreToDexieSchema } from '@core/JsStore/stores/shared/funcs/map-jsstore-to-dexie-schema';
import { modulesApiGetAll as pluginsApiGetAll, pluginsApiGetGeneratedModules } from '@core/Api/modules-api';
import { ComponentDesc, PluginFunc } from "@pluginShared/i-plugin-contract";
import { coreUiComponentDescriptions, CoreUiComponents } from "@pluginShared/core-ui-api";
import { addGeneratedPlugin, installPlugin, registerComponent } from '@core/Plugins/pluginManager';
import { entityApiBatchList, entityApiGet } from '@core/Api/entity-api';
import { getSheetData } from '@core/Api/google-api';
import { AutocompletePersistentStore } from "@core/JsStore/stores/AutocompletePersistentStore";
import { operationApiQuery } from '@core/Api/operation-api';
import { userApiGetTenantUsers } from '@core/Api/user-api';
import { component } from '@core/Plugins/pluginManager';
import { EventSourcingStore } from '@core/EventSourcing/EventSourcingStore';
import { ordersTableName, selectEntitiesFromDb } from '@core/JsStore/stores/orders-store';
import { QueueStore } from '@core/Stores/QueueStore';
import { useContext } from 'react';
import { ICrmApi } from '@pluginShared/i-crm-api';
import { getEntityTable as getEntityTable } from '@core/JsStore/stores/shared/funcs/common-store-funcs';
import { serializeError } from 'serialize-error';
import { externalEventApiAdd, externalEventApiGetLastStream } from '@core/Api/external-event-api';
import { generateKeywordsByEntity, KEYWORDS_GENERATOR_META_KEY, KEYWORDS_GENERATOR_VERSION } from '@core/EventSourcing/Implementation/KeywordsGenerator';
import { KVStore } from '@core/Stores/KVStore';
import { IndexableType, Table } from 'dexie';
import { t } from "i18next";


import '@core/VisualComponents/ArrayComponents/ArrayEditorInner/ArrayEditorInner';
import '@core/VisualComponents/ArrayComponents/ArrayEditorModal/ArrayEditorModal';
import '@core/VisualComponents/ArrayComponents/ArrayValuesViewer/ArrayValuesViewer';
import { IGeneratedModuleInfo } from '@core/Models/autogenerated/plugins.models';
import { IInstalledModule } from '@core/Models/installedModule';
import { IFetchOrdersQuery } from './ordersSlice/thunks/fetchOrdersAsync';
import _ from 'lodash';
import { clearObjectStoreByName, clearObjectStoresExcept } from 'src/Utils/indexeddbUtils';
import { setupLogger } from '@core/Services/logger-service';
import i18n from 'src/Locale/i18n';
import { phoneApiMakeCall } from '@core/Api/phone-api';
import { getDefaultTableId } from 'src/App/App';
import { AUTOCOMPLETE_META_KEY, AUTOCOMPLETE_VERSION, AutocompleteStore } from '@core/Shared/AutocompleteStore';
import { StoreContext } from '@core/Stores/EventSourcingStoreProvider';
import { EventSourcingReactLocalClient } from '@core/EventSourcing/EventSourcingReactLocalClient';
import { ITenantConfig, ITableConfig, CrmSortDirection } from '@core/Models/autogenerated/tenantConfig.models';
import { eventSourcingRpc } from '@core/config';
import { useAppSelector } from '../hooks';
import { selectTableConfig, selectTenantConfig } from '../store';

export interface IAppState {
    pluginsState: IPluginsState,
    dbSchema: IDataBase | null;
    userInfo: ICrmUserInfo | null;
    tenantConfig: ITenantConfig | null;
    userLogged: boolean,
    userNotLogged: boolean,
    currentTable: ITableConfig | null;
    status: 'initial' | 'loading' | 'ready' | 'core ready' | 'failed';
    appErrors: IAppError[],
}

export interface IPluginsState {
    modules: IInstalledModule[];
}

export interface IAppError {
    id: string,
    message: string,
}

const initialState: IAppState = {
    pluginsState: { modules: [] },
    dbSchema: null,
    userInfo: null,
    tenantConfig: null,
    userLogged: false,
    userNotLogged: false,
    currentTable: null,
    status: 'initial',
    appErrors: [],
}

interface InitApplicationAsyncResponse {
    dbSchema: IDataBase;
    userInfo: ICrmUserInfo;
    tenantConfig: ITenantConfig;
    pluginsState: IPluginsState;
    appErrors: IAppError[];
}

type IDbSchemaHash = Record<string, number>;

//todo: move to provider
let autocompleteStores: Record<string, AutocompleteStore> = {};
export function getAutocompleteStore(tableId: string) {
    if (!(tableId in autocompleteStores)) {
        const persistentStore = new AutocompletePersistentStore(tableId);
        autocompleteStores[tableId] = new AutocompleteStore(tableId, persistentStore);
    }
    return autocompleteStores[tableId];
}

export function createApi(userInfo: ICrmUserInfo): ICrmApi {
    return {
        configApi: {
            useConfig: () => useAppSelector(selectTenantConfig),
            useTableConfig: (tableId: string) => useAppSelector(selectTableConfig(tableId)),
        },
        entityApi: {
            entityApiGet: entityApiGet,
            select: (req: any) => entityApiGet({ tenant: { useDefaultTenant: true }, query: req }),
            entityApiBatchList: entityApiBatchList,

            //todo: add tableId
            getCachedValuesFromDumbCrmDb: (fieldName: string) => getAutocompleteStore(ordersTableName).getCachedValuesFromDumbCrmDb(fieldName),
            queryDeals: (query: IFetchOrdersQuery) => selectEntitiesFromDb(ordersTableName, query),
            queryEntities: (tableId: string, query: IFetchOrdersQuery) => selectEntitiesFromDb(tableId, query),
        },
        operationApi: {
            operationApiQuery: operationApiQuery
        },
        externalEventApi: {
            externalEventApiAdd: externalEventApiAdd,
            externalEventApiGetLastStream: externalEventApiGetLastStream,
        },
        userApi: {
            userApiGetTenantUsers: userApiGetTenantUsers,
            userApiGetUserInfo: userApiGetUserInfo,
        },
        googleApi: {
            getSheetData: getSheetData,
        },
        clientApi: {
            getUserInfo: () => userInfo,
        },
        phoneApi: {
            makeCall: (userPhone, targetPhone) => phoneApiMakeCall({user: userPhone, phone: targetPhone}),
        },
        coreUiApi: {
            component: component,
            componentDescriptions: coreUiComponentDescriptions,
            components: new CoreUiComponents(),
        },
        useStore: (tableId: string = ordersTableName) => useContext(StoreContext)[tableId],
        logger: Logger,
    };
}


const initFictiveNameAndClientId = () => {
    // Фиктивное имя для логирования
    if (!getValueFromLocalStorage(fakeUserNameKey))
        setValueToLocalStorage(fakeUserNameKey, faker.person.fullName());
    if (!getValueFromLocalStorage(clientInstanceIdKey))
        setValueToLocalStorage(clientInstanceIdKey, uuidv4());
};

const getDbSchema = (tables: ITableConfig[]): IDataBase => {
    const kvPairsTable = createKvPairsTable();
    const autocompleteSchema = tables.map(table => createAutocompleteTable(table.tableId));
    const eventSourcingSchema = tables.flatMap(table => EventSourcingReactLocalClient.getSchemas(table.tableId, table.fields));
    const queueStoreSchema = tables.flatMap(table => QueueStore.getSchemas(table.tableId));

    const dbSchema: IDataBase = {
        name: dbName,
        tables: [
            kvPairsTable
            , ...autocompleteSchema
            , ...eventSourcingSchema
            , ...queueStoreSchema
        ]
    };
    return dbSchema;
}

const initDb = async (dbSchema: IDataBase) => {
    //temorary open for reading old schemas and version
    let currentIndexedDbStores = new Set<string>();
    try {
        if (!db.isOpen())
            await db.open();
        currentIndexedDbStores = new Set(db.backendDB().objectStoreNames);
    } catch (err) {
        Logger.debug("Possibly database not created yet", err);
    }


    if (db.isOpen())
        db.close();

    const previousDbSchemaHash = getValueFromLocalStorage<IDbSchemaHash | null>(dbSchemaHashKey) ?? {};
    const targetDbSchemaHash: IDbSchemaHash = {};

    const dexieSchema = mapJsStoreToDexieSchema(dbSchema);

    let currentTableSet = new Set(dbSchema.tables.map(x => x.name));
    let previousTableSet = new Set(Object.keys(previousDbSchemaHash));

    let upgradeRequired = !_.isEqual(currentTableSet, previousTableSet);
    if (currentIndexedDbStores != null) {
        if (!_.isEqual(currentIndexedDbStores, currentTableSet))
            upgradeRequired = true;
    }

    for (let tableSchema of dbSchema.tables) {
        let currentHash = murmurhash(JSON.stringify(tableSchema));
        let previousHash = previousDbSchemaHash != null ? previousDbSchemaHash[tableSchema.name] : null;

        //todo: убрать previousHash != null. проверка только для клиентов старой версии (чтобы данные лишний раз не стирались)
        if (previousHash != null && currentHash != previousHash) {
            upgradeRequired = true;

            await clearObjectStoreByName(dbName, tableSchema.name);
        }

        targetDbSchemaHash[tableSchema.name] = currentHash;
    }

    let version = Math.max(db.verno, 10);
    if (upgradeRequired) {
        version += 10;
        Logger.debug(`upgrade db to version ${version}`);
    }

    console.log("Current database version:", db.verno);

    await db
        .version(version)
        .stores(dexieSchema)
        .upgrade(t => {
            Logger.debug(`initDb upgrade event`);
        });

    Logger.debug(`db.verno=${db.verno}`);

    if (!db.isOpen()) {
        await db.open();
    }

    Logger.debug("db opened");
    
    setValueToLocalStorage(dbSchemaHashKey, targetDbSchemaHash);

    return dbSchema;
};

async function initPluginsAndComponents(crmApi: ICrmApi): Promise<[IPluginsState, string[]]> {
    let pluginsState: IPluginsState = { modules: [] };
    let appErrors: string[] = [];

    let generatedPluginsInfo: IGeneratedModuleInfo[] = [];
    try {
        generatedPluginsInfo = await pluginsApiGetGeneratedModules({ tenant: { useDefaultTenant: true } });
    }
    catch (e: any) {
        Logger.error("Error while loading generated modules", e);
        appErrors.push("Error while loading generated modules");
    }

    for (let pluginInfo of generatedPluginsInfo) {
        try {
            const installedModule = await addGeneratedPlugin(pluginInfo, crmApi)
            pluginsState.modules.push(installedModule);
        }
        catch (e: any) {
            Logger.error(`Error while loading plugin with name '${pluginInfo.name}'`, e);
            appErrors.push(`Error while loading plugin with name '${pluginInfo.name}'`);
        }
    }

    const pluginsInfo = await pluginsApiGetAll({ tenant: { useDefaultTenant: true } });

    for (let pluginInfo of pluginsInfo) {
        await import(/*webpackIgnore: true*/pluginInfo.jsUrl);
        const pluginFunc = (window as any)['module_' + pluginInfo.name] as PluginFunc;

        let plugin = pluginFunc(crmApi);

        const installedModule = installPlugin(plugin, pluginInfo);
        pluginsState.modules.push(installedModule);
    }

    return [pluginsState, appErrors];
}

export function initApplicationCore() {
    try {
        initFictiveNameAndClientId();

        return true;
    }
    catch (e) {
        Logger.error('Application core failed to load', e);
        return false;
    }
}

// Вспомогательная функция для пакетного сохранения
async function saveBatch(batch: any[], ordersTable: Table<any, IndexableType>) {
    await db.transaction('rw', ordersTable, async () => {
        await ordersTable.bulkPut(batch);
    });
}

async function regenerateKeywords(tableConfig: ITableConfig) {
    const regenerateKeywordsStartTime = performance.now();
    let generateKeywordsByEntityDurationSum = 0;

    Logger.debug(`[appSlice] start regenerateKeywords`);

    const table = getEntityTable(tableConfig.tableId);

    // Размер пакета
    const batchSize = 1000;

    let offset = 0;
    let moreItems = true;

    while (moreItems) {
        // Начинаем транзакцию для чтения
        const items = await table
            .offset(offset)
            .limit(batchSize)
            .toArray();

        if (items.length > 0) {
            let batch = [];
            
            for (const rawItem of items) {
                if (rawItem != null) {
                    const generateKeywordsByEntityStartTime = performance.now();
        
                    // Генерируем ключевые слова
                    rawItem._keywords = generateKeywordsByEntity({
                        lastEventNumber: 0,
                        keywords: [],
                        entityId: rawItem.id,
                        entityData: rawItem,
                    }, tableConfig, i18n.language);
        
                    generateKeywordsByEntityDurationSum += performance.now() - generateKeywordsByEntityStartTime;
        
                    // Добавляем запись в текущий пакет
                    batch.push(rawItem);
                }
            }

            // Сохраняем пакет в БД
            await saveBatch(batch, table);

            // Обновляем смещение для следующей порции данных
            offset += batchSize;
        } else {
            moreItems = false;
        }
    }

    Logger.debug(`[appSlice] regenerate keywords in ${tableConfig.tableId} completed in ${performance.now() - regenerateKeywordsStartTime} ms`);
    Logger.debug(`[appSlice] generateKeywordsByEntityDurationSum is ${generateKeywordsByEntityDurationSum} ms`);
}

async function regenerateAutocompleteValues(tableConfig: ITableConfig) {
    const regenerateAutocompleteStartTime = performance.now();

    const { tableId } = tableConfig;
    const table = getEntityTable(tableId);
    const autocompleteStore = getAutocompleteStore(tableId);

    // Объект для хранения уникальных значений для каждого поля
    const uniqueValues: { [key: string]: Record<string, number> } = {};

    const insertValue = (record: any, prevKey?: string) => {
        Object.keys(record).forEach(key => {
            if (key == "_keywords" || key == "id") {
                return;
            }

            const value = record[key];
            const cacheKey = (prevKey ? prevKey + "." : "") + key;

            if (value != null && value !== "" && (typeof value === "string" || typeof value === "number")) {
                const strValue = value.toString().trim();
                if (!uniqueValues[cacheKey]) {
                    uniqueValues[cacheKey] = {};
                }
                if (!uniqueValues[cacheKey][strValue]) {
                    uniqueValues[cacheKey][strValue] = 0;
                }
                uniqueValues[cacheKey][strValue] += 1;
            }

            if (Array.isArray(value)) {
                for (const arrayValue of value) {
                    insertValue(arrayValue, cacheKey);
                }
            }
        });
    }

    // Использование курсора для итерации по записям
    await table.each(record => {
        insertValue(record);
    });

    // Очистка таблицы автокомплита
    await autocompleteStore.clear();

    // Вставка новых значений в таблицу автокомплита
    for (const field of Object.keys(uniqueValues)) {
        await autocompleteStore.insertAutocompleteValueToDumbCrmDb(field, uniqueValues[field]);
    }

    Logger.debug(`[appSlice] regenerate autocomplete values in ${tableConfig.tableId} completed in ${performance.now() - regenerateAutocompleteStartTime} ms`);
}

export const initApplicationAsync = createAsyncThunk<InitApplicationAsyncResponse, void>(
    'app/initApplicationAsync',
    async (_, thunkAPI) => {
        try {
            Logger.debug("Init application");

            const accessToken = getValueFromLocalStorage(accessTokenKey);
            if (!accessToken)
                return {} as InitApplicationAsyncResponse;

            let userInfo: ICrmUserInfo | null = null;
            try {
                userInfo = await userApiGetUserInfo({});
                setupLogger(userInfo);
                Logger.debug(`User loaded: ${userInfo.login}, ${userInfo.tenant}`);
            }
            catch (e) {
                const error = e as AxiosError;
                if (error.response && error.response.status === 404) {
                    return {} as InitApplicationAsyncResponse;
                }
                else if (error.response && error.response.status === 401) {
                    return {} as InitApplicationAsyncResponse;
                } else
                    throw e;
            }
            let tenantConfig = await getTenantConfig({ defaultConfig: true });
            tenantConfig = migrateConfig(tenantConfig);

            const previousTenant = getValueFromLocalStorage<string | null>(dbTenantKey);
            if (userInfo?.tenant != null && previousTenant != null && previousTenant != userInfo?.tenant) {
                Logger.debug(`user changed, clear user tables: ${userInfo?.tenant} != ${previousTenant}`);
                //await clearDb(igonerTables);
                await clearObjectStoresExcept(dbName, (name) => !name.endsWith('_queue'));
            }
            setValueToLocalStorage(dbTenantKey, userInfo.tenant);

            const dbSchema = getDbSchema(tenantConfig.tables);

            await initDb(dbSchema);

            if (!eventSourcingRpc(tenantConfig.esnode).enabled) {
                for (let tableConfig of tenantConfig.tables) {
                //for diagnostic
                    let entityTable = getEntityTable(tableConfig.tableId);
                    db.transaction('r', entityTable, async () => {
                        const count = await entityTable.count();
                        Logger.info(`Db ${tableConfig.tableId} contains ${count} rows. UserInfo: ${userInfo!.login}, ${userInfo!.tenant}`);
                    });

                    const entityTableMeta = new KVStore(`${tableConfig.tableId}_meta`);
                    const keywordsVersion = await entityTableMeta.get(KEYWORDS_GENERATOR_META_KEY);
                    if (keywordsVersion == null || keywordsVersion !== KEYWORDS_GENERATOR_VERSION) {
                        regenerateKeywords(tableConfig).then(async () => {
                            await entityTableMeta.set(KEYWORDS_GENERATOR_META_KEY, KEYWORDS_GENERATOR_VERSION);
                            Logger.debug(`[appSlice] update keywords generator version in ${tableConfig.tableId} to ${KEYWORDS_GENERATOR_VERSION}`);
                        })
                        .catch(err => {
                            Logger.error("regenerateKeywords failed", err);
                        });
                    }

                    const autocompleteVersion = await entityTableMeta.get(AUTOCOMPLETE_META_KEY);
                    if (autocompleteVersion == null || autocompleteVersion !== AUTOCOMPLETE_VERSION) {
                        regenerateAutocompleteValues(tableConfig).then(async () => {
                            await entityTableMeta.set(AUTOCOMPLETE_META_KEY, AUTOCOMPLETE_VERSION);
                            Logger.debug(`[appSlice] update autocomplete values version in ${tableConfig.tableId} to ${AUTOCOMPLETE_VERSION}`);
                        })
                        .catch(err => {
                            Logger.error("regenerateAutocompleteValues failed", err);
                        });
                    }
                }
            } else {
                Logger.debug("Use esnode");
            }

            Logger.debug("Loading plugins");
            const crmApi = createApi(userInfo);
            let pluginsState: IPluginsState = { modules: [] };
            let appErrors: string[] = [];
            try {
                [pluginsState, appErrors] = await initPluginsAndComponents(crmApi);
            }
            catch (e: any) {
                Logger.error("Loading plugins failed", e);
            }

            Logger.debug("Init completed");

            return {
                dbSchema,
                userInfo,
                tenantConfig: tenantConfig,
                pluginsState: pluginsState,
                appErrors: appErrors.map(message => ({ id: uuidv4(), message })),
            } as InitApplicationAsyncResponse;
        } catch (e: any) {
            Logger.error('Application failed to load', serializeError(e, { maxDepth: 2 }));
            return thunkAPI.rejectWithValue("Application failed to load");
        }

    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        saveConfig: (state: IAppState, action) => {
            if (state) {
                state.tenantConfig = action.payload;
            }
        },
        addModule(state: IAppState, action: PayloadAction<IInstalledModule>) {
            if (state) {
                state.pluginsState = ({
                    ...state.pluginsState,
                    modules: [...state.pluginsState.modules, action.payload]
                } as IPluginsState)
            }
        },
        removeModule(state: IAppState, action: PayloadAction<IInstalledModule>) {
            if (state) {
                state.pluginsState = ({
                    ...state.pluginsState,
                    modules: state.pluginsState.modules.filter(x => x.info.name != action.payload.info.name)
                } as IPluginsState)
            }
        },
        addAppError(state: IAppState, action: PayloadAction<string>) {
            if (state) {
                state.appErrors.push({
                    id: uuidv4(),
                    message: action.payload,
                });
            }
        },
        deleteAppError(state: IAppState, action: PayloadAction<string>) {
            if (state) {
                state.appErrors = state.appErrors.filter(x => x.id !== action.payload)
            }
        },
        openTable(state: IAppState, action: PayloadAction<{table: ITableConfig}>) {
            state.currentTable = action.payload.table;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(initApplicationAsync.pending, (state: IAppState) => {
                state.status = 'loading';
            })
            .addCase(initApplicationAsync.rejected, (state: IAppState) => {
                state.status = 'failed';
            })
            .addCase(initApplicationAsync.fulfilled, (state: IAppState, action) => {
                const response = action.payload;
                if (response !== null && response.dbSchema !== undefined) {
                    state.dbSchema = response.dbSchema;
                    state.tenantConfig = response.tenantConfig;
                    state.pluginsState = response.pluginsState;
                    state.appErrors = response.appErrors;
                }

                if (response !== null && response.userInfo != null) {
                    state.userInfo = response.userInfo;
                    state.userLogged = true;
                } else {
                    state.userNotLogged = true;
                }

                state.status = 'ready';
            })
    }
});


export const { saveConfig, addAppError, deleteAppError, openTable } = appSlice.actions;
function migrateConfig(config: any) {
    let result: ITenantConfig | any = config;

    if (config.tables == null && config.fields != null) {
        result.tables = [
            {
                tableId: result.tableId ?? ordersTableName,
                tableName: result.tableName ?? t("orders"),
                fields: result.fields,
                filters: result.filters ?? [],
                sortField: result.sortField ?? 'createdAt',
                sortDirection: result.sortDirection ?? CrmSortDirection.Desc
            } as ITableConfig
        ];
    }

    return result;
}

export default appSlice.reducer;

