import { ModalWindow } from "@core/VisualComponents/ModalWindow/ModalWindow";
import { CreateOrEditEntity, ICreateOrEditEntityCommands } from "./CreateOrEditEntity";
import { useRef } from "react";
import { t } from "i18next";
import { Breadcrumb, message } from "antd";
import styles from "./CreateOrEditEntity.module.scss";
import { ReactComponent as CopyLinkIcon } from '@assets/Icons/link-icon.svg';

export interface ICreateOrEditEntityModalProps {
    tableId: string;
    entityId?: string;
    onHide: () => void;
}

export function CreateOrEditEntityModal (props: ICreateOrEditEntityModalProps) {
    const orderChanged = useRef<boolean>(false);
    const externalCommands = useRef<ICreateOrEditEntityCommands>({});

    const onHide = () => {
        let confirmed = true;
        if (orderChanged.current) {
            // eslint-disable-next-line no-restricted-globals
            confirmed = confirm(t("confirm-drop-changes"));
        }

        return confirmed;
    }

    const onSave = () => {
        return !!externalCommands.current.onCreate?.()
            || !!externalCommands.current.onUpdate?.();
    }

    const onCopyLink = () => {
        navigator.clipboard.writeText(window.location.origin + "/" + props.tableId + "/edit/" + props.entityId);
        message.success(t("link-copied"));
    }

    return <ModalWindow
        title={<>
            <Breadcrumb className={styles.breadcrumb} items={[
                { title: props.entityId ? t("edit-record") : t("new-record") },
            ]}/>
            {props.entityId && <CopyLinkIcon className={styles.copyIcon} onClick={onCopyLink}/>}
        </>}
        beforeHide={onHide}
        onHide={props.onHide}
        beforeSave={onSave}
        onSave={() => {}}
        saveButtonText={props.entityId ? t("save") : t("create")}
    >
        <CreateOrEditEntity
            tableId={props.tableId}
            entityId={props.entityId}
            orderChanged={orderChanged}
            onCreateOrUpdateOrderClicked={props.onHide}
            onCancelClicked={onHide}
            externalCommands={externalCommands}
        />
    </ModalWindow>
}